@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  font-weight: medium;
  src: url('../Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: bold;
  src: url('../Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: bolder;
  src: url('../Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Inter';
  font-weight: normal;
  src: url('../Inter-Regular.ttf') format('truetype');
}

:root {
  --blue: #66ccff;
  --darkGrey: #666666;
  --linkBlue: #0c92d5;
  --black: #000000;
  --white: #ffffff;
  --grey900: #475467;
  --grey800: #667085;
  --grey700: #6f767e;
  --grey600: #778293;
  --grey500: #9aa4b2;
  --grey400: #d0d5dd;
  --grey300: #eaecf0;
  --grey200: #f2f4f7;
  --grey100: #f9fafb;
  --blue900: #0c92d5;
  --blue800: #28a5e3;
  --blue700: #a4d0e6;
  --blue300: #eaf8ff;
  --blue200: #f1faff;
  --blue100: #f8fdff;
  --orange900: #ffa000;
  --orange800: #e28e00;
  --orange200: #ffe7be;
  --orange100: #FF9F0;
  --green900: #66cc33;
  --green800: #5bb72d;
  --green300: #bfe4ad;
  --green200: #e5fad7;
  --green100: #fafff4;
  --red900: #b42318;
  --red800: #db483e;
  --red700: #f97066;
  --red400: #f7a49d;
  --red100: #fef3f2;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html,
body {
  height: 100%;
  font-family: 'Inter', sans-serif;
  /* color: var(--grey100); */
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased !important;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

ul[role='list'],
ol[role='list'] {
  list-style: none;
}

#root,
#__next {
  isolation: isolate;
}

.forgot-password-link {
  color: var(--linkBlue);
  cursor: pointer;
  font-weight: 500;
}

.sku-link,
.order-item-link,
.breadcrumb-item,
.transaction-link {
  color: var(--linkBlue);
  text-decoration: underline;
}

.breadcrumb-item-disabled {
  color: var(--darkGrey);
  text-decoration: none;
}

.cartImagePreviewContainer {
  border: 1px dashed #ccc;
  margin: 12px 0;
  padding: 16px 5px;
  max-width: 400px;
  max-height: 250px;
}

.cartImagePreview {
  width: 200px;
  max-height: 180px;
  margin-left: 23px;
}

.readMore {
  color: #66ccff;
  text-decoration: underline;
  cursor: pointer;
  /* margin-top: 5px; */
}

.readMore-wrap-word {
  width: inherit;
  text-wrap: wrap;
  overflow-y: auto;
  max-height: 80px;
  box-sizing: border-box;
  word-wrap: break-word;
  padding: 2px;
}

.text-line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-line-clamp-1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sku:hover,
.sku:visited,
.sku:active,
.sku:focus,
.transaction-link:hover,
.transaction-link:visited,
.transaction-link:active,
.transaction-link:focus {
  color: var(--linkBlue);
}

.vendor-link,
.hoover-product-link {
  color: var(--linkBlue);
  cursor: pointer;
}

.vendor-link:hover,
.vendor-link:visited,
.vendor-link:active,
.vendor-link:focus,
.hoover-product-link:hover,
.hoover-product-link:visited,
.hoover-product-link:active,
.hoover-product-link:focus {
  color: var(--linkBlue);
}

.sku-link,
.order-item-link,
.vendor-link {
  cursor: pointer;
  text-decoration: underline;
}

.sku:hover,
.vendor-link:hover,
.sku:visited,
.vendor-link:visited,
.sku:active,
.vendor-link:active,
.sku:focus,
.vendor-link:focus {
  text-decoration: underline;
}

.green {
  color: #66cd33;
}

.red {
  color: #ee0000;
}

.blue {
  color: #66ccff;
}

.orange-bg {
  background: #ff9900;
}

.blue-bg {
  background: #66ccff;
}

.switch.on {
  background-color: #58d064 !important;
}

.form-select__menu {
  z-index: 2 !important;
}

.MuiDialog-container h2.MuiTypography-root span {
  font-weight: 600;
}

.MuiDialogContent-root {
  overflow-x: hidden;
}

.cardImage {
  border-bottom: 1px solid #dedede !important;
}

.table-date-filter-input>div {
  display: flex;
}

.table-date-filter-input input {
  font-size: 14px !important;
  font-weight: 600;
  color: var(--grey900);
}

.table-date-filter-input input::placeholder {
  font-weight: 600 !important;
  color: var(--grey600);
  opacity: 1;
}

.background-white {
  background-color: white !important;
}

body {
  background-color: var(--grey100);
}

.text-wrap {
  text-wrap: wrap;
}

*::-webkit-scrollbar {
  width: 1em;
  height: 1em;
}

*::-webkit-scrollbar-track {
  background-color: var(--white);
  border: 1px solid var(--grey300);
  box-sizing: content-box;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--grey300);
  border-radius: 8px;
  border: 4px solid var(--white);
  box-shadow: 4px solid var(--white);
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-x-hide::-webkit-scrollbar {
  height: 0;
}

.scrollbar-y-hide::-webkit-scrollbar {
  width: 0;
}

/* below css make the input field disable */
input:not(.MuiInput-input):disabled {
  background-color: var(--grey200);
  border-radius: 8px !important;
  border-color: var(--grey500);
}

input::placeholder {
  color: var(--grey600) !important;
}

/* below css is used for  match the border-radius with controls  */
.flag-dropdown {
  border-radius: 8px 0 0 8px !important;
}

.flag-dropdown .selected-flag:hover {
  background: transparent !important;
  border-radius: 8px 0 0 8px !important;
}

textarea::-webkit-scrollbar-track {
  border: none;
}

textarea {
  background-size: 20px !important;
  background-color: transparent !important;
  max-height: 700px;
}

::-webkit-resizer {
  background-image: url('/public/images/resize-icon.png') !important;
  background-size: contain;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.MuiSwitch-track {
  background-color: var(--grey300) !important;
  opacity: 1 !important;
}

.Mui-checked+.MuiSwitch-track {
  background-color: var(--green900) !important;
  opacity: 1 !important;
}

.MuiDataGrid-columnsPanelRow .Mui-disabled .MuiSwitch-sizeSmall .MuiSwitch-track {
  opacity: 0.5 !important;
}

/* below css is used when the screen size wider then 1920px */
@media screen and (min-width: 1921px) {
  .mainLayoutWrapper {
    max-width: 1380px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1441px) {
  .shopMainLayoutWrapper {
    max-width: 1380px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 768px) {
  .companyMainLayout {
    width: min-content !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media screen and (max-width: 1169px) {
  .contactMainLayout {
    width: min-content !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

.text-overflow-clip {
  text-overflow: clip !important;
}

.warehouse-setup-radio-container .MuiFormGroup-root {
  flex-direction: row;
  gap: 1rem;
}

.password-input .MuiFormHelperText-root {
  min-width: 30vw;
}

.MuiDataGrid-root {
  --DataGrid-hasScrollY: 0 !important;
}

.modal-subheading {
  color: var(--grey900);
}
